import { call, fork, put, take } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { loginApi } from 'api/loginApi'
import { SMC_AUTH } from 'configs/constants'
import { getDataStorage, removeAllDataStorage, setDataStorage } from 'utils'
import { IHistory, ILoginAction, LoginResponse, ResponseData } from 'models'

import { authAction } from './authSlice'

function* handleLogin({ email, password, history }: ILoginAction) {
  try {
    const payload = { email, password }
    const response: ResponseData<LoginResponse> = yield call(
      loginApi.login, payload,
    )
    if (response) {
      yield put(authAction.loginSuccess(response.data))
      setDataStorage(SMC_AUTH, response.data.token)
      history.push('/')
    }
  } catch (error: any) {
    toast.error(error?.message, {
      theme: 'colored',
      autoClose: 2500,
    })
    yield put(authAction.loginFailed(history))
  }
}

function* handleLogout(history: IHistory) {
  removeAllDataStorage()
  yield put(authAction.loginFailed(history))
  history.push('/login');
}

function* watchLoginFlow() {
  while (true) {
    const isSigned = Boolean(getDataStorage(SMC_AUTH))
    if (!isSigned) {
      const action: PayloadAction<ILoginAction> = yield take(
        authAction.login.type,
      )
      yield fork(handleLogin, action.payload)
    }
    const action: PayloadAction<IHistory> = yield take([authAction.logout.type, authAction.loginFailed.type])
    // use `call` -> blocking
    yield call(handleLogout, action.payload)
  }
}

export default function* authSaga() {
  yield fork(watchLoginFlow)
}
