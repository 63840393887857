import { lazy } from 'react'
import { IRoute } from 'models'

import { AdminLayout } from './components/Layout'
import { PATH_NAME } from 'configs/constants'

import AuthGuard from 'guards/AuthGuard'
import GuestGuard from 'guards/GuestGuard'

const LoginPage = lazy(() => import('features/auth/pages/LoginPage'))
const DashboardPage = lazy(() => import('features/dashboard'))

export const routes: IRoute[] = [
  {
    path: PATH_NAME.ROOT,
    exact: true,
    layout: AdminLayout,
    guard: AuthGuard,
    component: DashboardPage,
  },
  {
    exact: true,
    path: PATH_NAME.LOGIN,
    guard: GuestGuard,
    component: LoginPage,
  },
]
