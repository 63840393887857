import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IHistory, ILoginAction, LoginResponse, Profile } from 'models'

export interface AuthState {
  isLoggedIn: boolean
  logging: boolean
  isLoading: boolean
  user: Profile | {}
}

const initialState: AuthState = {
  isLoggedIn: false,
  logging: false,
  isLoading: false,
  user: {},
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<ILoginAction>) => {
      state.logging = true
    },
    loginSuccess: (state, action: PayloadAction<LoginResponse>) => {
      state.logging = false
      state.isLoggedIn = true
    },
    loginFailed: (state, action: PayloadAction<IHistory>) => {
      return initialState
    },
    logout: (state, action: PayloadAction<IHistory>) => {
      return initialState
    },
  },
  extraReducers: (builder) => {
  },
})

const { actions, reducer } = authSlice

export const authAction = actions

export default reducer
