import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material'
import { StatusChange } from 'models'
import React, { memo } from 'react'
import { STATUS } from '../../configs/constants'

interface ModalConfirmProps {
  isToggle: boolean
  isLoading: boolean
  status: StatusChange
  onClose: () => void
  onSendNotify: ()  => Promise<number>
  onUpdateStatus: () => Promise<number>
}

export const ModalConfirm: React.FC<ModalConfirmProps> = memo((props) => {
  const { isToggle, isLoading, status, onClose, onSendNotify, onUpdateStatus } = props

  const contentNotify = (status: StatusChange) => {
    switch (true) {
      case status === STATUS.BLOCK:
        return `Êtes-vous sûr de vouloir débloquer cet utilisateur ?`
      case status === STATUS.ACTIVE:
        return `Êtes-vous sûr de vouloir bloquer cet utilisateur ?`
      default:
        return 'Êtes-vous sûr de vouloir envoyer une notification à cet utilisateur ?'
    }
  }

  const onConfirm = () => {
    const fn = status === STATUS.NOT_ALLOW ? onSendNotify : onUpdateStatus;
    fn().then(onClose)
  }

  return (
    <Modal
      open={isToggle}
      onClose={onClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      <Box
        width={600}
        style={{
          background: '#fff',
          borderRadius: 15,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'hidden',
        }}>
        <Typography
          align='center'
          variant='h4'
          style={{
            padding: '30px 39px',
          }}>
          {contentNotify(status)}
        </Typography>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          style={{
            background: '#D5E9F6',
            padding: '15px 0',
          }}>
          <Button
            onClick={onClose}
            color='primary'
            variant='outlined'
            style={{
              borderRadius: 54,
              maxWidth: 160,
              width: '100%',
              border: '1.5px solid #2887C7',
              marginRight: 15,
              background: '#fff',
            }}>
            NON
          </Button>
          <Button
            onClick={onConfirm}
            disabled={isLoading}
            color='primary'
            variant='contained'
            style={{
              borderRadius: 54,
              maxWidth: 160,
              width: '100%',
              marginLeft: 15,
            }}>
            {isLoading && (
              <Box pr={1}>
                <CircularProgress size={12} color='secondary' />
              </Box>
            )}
            OUI
          </Button>
        </Box>
      </Box>
    </Modal>
  )
})
