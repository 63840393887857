import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import { CircularProgress, CssBaseline, PaletteMode } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { useGlobalContext } from 'context/GlobalContext'
import { ToastContainer } from 'react-toastify'
import { getDesignTokens } from 'style/theme'

import { history } from 'utils'

import { store } from './app/store'

import 'style/css-variable.css'

export const Providers: React.FC = ({ children }) => {
  const { modeTheme } = useGlobalContext() as {
    modeTheme: PaletteMode
    setModeTheme: (param: string) => void
  }

  const theme = createTheme(getDesignTokens(modeTheme))

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Suspense fallback={<CircularProgress />}>
            <ToastContainer />
            {children}
          </Suspense>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  )
}
