import React, { FC } from 'react'
import { Redirect } from 'react-router-dom'

import { PATH_NAME, SMC_AUTH } from 'configs/constants'
import { getDataStorage } from 'utils'

const GuestGuard: FC = ({ children }) => {
  let isAuth = Boolean(getDataStorage(SMC_AUTH))

  if (isAuth) return <Redirect to={PATH_NAME.ROOT} />
  return <> {children}</>
}

export default GuestGuard
