import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { combineReducers } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import rootSaga from './rootSaga'
import authReducer from 'features/auth/authSlice'
import { history } from 'utils'
// create saga
const sagaMiddleware = createSagaMiddleware()

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth: authReducer,
})
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware, routerMiddleware(history)),
})

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>;
