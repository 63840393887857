import authSaga from 'features/auth/authSaga'
import { all } from 'redux-saga/effects'

function* helloSaga() {
  console.log('Welcome to Admin SMC Protect ')
  yield 1
}
export default function* rootSaga() {
  yield all([helloSaga(), authSaga()])
}
