import { StatusChange } from 'models'

type T = 'BLOCK' | 'ACTIVE' | 'INACTIVE' | 'NOT_ALLOW'
export const STATUS: { [key in T]: StatusChange } = {
  BLOCK: 'BLOCK',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  NOT_ALLOW: 'NOT_ALLOW',
}

export const TEXT_STATUS: { [key in T]: string } = {
  BLOCK: 'BLOCK',
  INACTIVE: 'Non Payé',
  ACTIVE: 'Payé',
  NOT_ALLOW: 'NOT_ALLOW',
}
