import React from 'react'

import { AppBar, Avatar, Box, Button, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import { WithStyles, withStyles } from '@mui/styles'
import { useAppDispatch } from 'app/hooks'

import defaultAvatar from 'assets/images/default-avatar.png'
import logo from 'assets/images/logo.svg'
import { authAction } from 'features/auth/authSlice'
import { useHistory } from 'react-router-dom'

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    // marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontSize: '1.125rem',
    fontWeight: 600,
  },

  logo: {
    width: 160,
    height: 60,
  },
  avatar: {
    width: 42.75,
    height: 42.75,
  },
})

export const Header = withStyles(styles)((props: WithStyles<typeof styles>) => {
  const { classes } = props
  const theme = useTheme()

  const hasMatches = useMediaQuery(theme.breakpoints.down('lg'))

  const dispatch = useAppDispatch()
  const history = useHistory();
  const handleLogout = () => {
    dispatch(authAction.logout(history))
  }

  return (
    <Box className={classes.root}>
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu">
            {/* <MenuIcon /> */}
          </IconButton>
          <div className={classes.logo}>
            <img src={logo} alt="logo" />
          </div>
          {!hasMatches && (
            <Typography variant="h2" className={classes.title}>
              Panneau d’administration
            </Typography>
          )}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flex={1}>
            <Avatar alt="Remy Sharp" src={defaultAvatar} />
            <Box paddingLeft={2}>
              <Typography variant="subtitle2"> Super Admin</Typography>
            </Box>
            <Box paddingLeft={2}>
              <Button color="primary" variant="outlined" onClick={handleLogout}>
                Logout
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
})
